<template>
  <processesForm ref="processes-form" />
</template>

<script>
import processesForm from '@/views/settings/fulfillment/processes/components/ProcessesForm.vue'
import processModule from '@/store/settings/fulfillment/processes'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'Processes',
  components: { processesForm },
  data() {
    return {
      loading: true,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['processes-form']) this.$refs['processes-form'].showConfirmationModal(next)
  },
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-processes')
  },
  setup() {
    const MODULE_NAME = 'process'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, processModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>

import axios from '@/libs/axios'
// import crudActions from '@/libs/storeConstActions'

const endpoint = 'settings/fullfilment/processes'
const getSettings = () => axios.get(`/${endpoint}`)
const update = (ctx, data) => axios.post(`/${endpoint}`, data)

// const Actions = crudActions(endpoint)

export default {
  getSettings,
  update,
  // ...Actions,
}

export default {
  GET(state, data) {
    state.process = { ...data }
  },
  LIST(state, data) {
    state.processes = data
  },
  SET_PROCESS_FORM(state, data) {
    state.processForm = { ...data }
  },
}
